import PinchZoom from 'pinch-zoom-js'
import './style.css'
const dragEndOffset = 10

let previousEventDragPosition = false

let swipedSideCounter = {
  leftSide: 0,
  rightSide: 0
}

document.addEventListener('DOMContentLoaded', () => {
  const zoomTargarts = document.querySelectorAll('.zoom-target')

  zoomTargarts.forEach((target) => {
    const pz = new PinchZoom(target, {
      draggableUnzoomed: false,
      minZoom: 1,
      maxZoom: 4,
      useMouseWheel: false,
      useDoubleTap: false,
      draggableUnzoomed: false,
      onDragEnd: dragEnd
    })

    target.addEventListener('click', (e) => {
      const size = {
        x: e.target.clientWidth,
        y: e.target.clientHeight
      }

      if (pz.zoomFactor > 1) {
        size.x = 0
        size.y = 0
      }

      target.style.transition = 'ease-in-out 0.3s transform'
      pz.offset.x = size.x / 2
      pz.offset.y = size.y / 2
      pz.zoomFactor = pz.zoomFactor === 1 ? 2 : 1
      pz.update()

      setTimeout(() => {
        target.style.transition = 'none'
      }, 300)
    })
  })
})

function dragEnd(object, e) {
  const slideWidth = Math.round(e.target.clientWidth)
  const lastDragPosition = Math.round(object.lastDragPosition.x)
  const lastSlidePosition = Math.round(object.offset.x)

  if (!previousEventDragPosition) {
    previousEventDragPosition = slideWidth / 2
  }
  if (lastDragPosition < -dragEndOffset && slideWidth == lastSlidePosition) {
    // console.log(slideWidth, lastDragPosition, lastSlidePosition)
    resetZoom(object, 'toRight')
    swipedSideCounter.rightSide = 0
  } else if (slideWidth === lastSlidePosition) {
    //Swipped image to the left, but not beyond parent container
    console.log('swiped to left side of image')

    swipedSideCounter.rightSide++
  }

  if (lastDragPosition > slideWidth + dragEndOffset) {
    resetZoom(object, 'toLeft')
    swipedSideCounter.leftSide = 0
  } else if (lastSlidePosition === 0) {
    //Dragged image to the right, but not beyond parent container
    console.log('swiped to right side of image')
    swipedSideCounter.leftSide++
  }

  console.log(previousEventDragPosition, lastSlidePosition)
  if (previousEventDragPosition > lastSlidePosition) {
    console.log('Swiped right')
    swipedSideCounter.rightSide = 0
  } else if (previousEventDragPosition < lastSlidePosition) {
    console.log('Swiped left')
    swipedSideCounter.leftSide = 0
  }

  if (swipedSideCounter.leftSide > 1 || swipedSideCounter.rightSide > 1) {
    const nextDirection =
      swipedSideCounter.leftSide > swipedSideCounter.rightSide
    resetZoom(object, nextDirection ? 'toLeft' : 'toRight')
  }

  console.log(swipedSideCounter)

  previousEventDragPosition = lastSlidePosition
}

function resetZoom(object, nextDirection) {
  object.zoomOutAnimation()
  previousEventDragPosition = false
  swipedSideCounter.leftSide = 0
  swipedSideCounter.rightSide = 0

  console.log(nextDirection)
}
